<template>
  <default-layout>
    <div class="container help--container">
      <banner :banner-text="bannerText" />
      <div class="help--wrapper">
        <div class="left--col">
          <page-select></page-select>
        </div>
        <div class="right--col" v-if="selectedHelp">
          <div class="help--content">
            <div class="content--text">
              <propfix-general v-if="selectedHelp.url === 'what-is-propfix'" />
              <about-listing v-else-if="selectedHelp.url === 'about-listing'" />
              <delete-account v-else-if="selectedHelp.url === 'account-deletion'" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <script v-html="jsonld" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Banner from '@/components/utils/page-banner';
import PageSelect from '@/components/utils/page-select';
const PropfixGeneral = () => import('@/components/static-page/help/propfix-general');
const AboutListing = () => import('@/components/static-page/help/seputar-listing');
const DeleteAccount = () => import('@/components/static-page/help/delete-account');
import DefaultHeadMixin from '@/mixins/defaultHead';
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  mixins: [DefaultHeadMixin, HelperMixin],
  components: {
    DefaultLayout,
    Banner,
    PageSelect,
    PropfixGeneral,
    AboutListing,
    DeleteAccount,
  },
  computed: {
    ...mapState({
      selectedHelp: state => state.v2.help.selectedHelp,
      helpList: state => state.v2.help.helpList,
    }),
    jsonld() {
      return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: this.$t('FAQ.rentfixgeneral'),
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                this.$t('FAQ.rentfixgeneraldesc1') +
                ' ' +
                this.$t('FAQ.rentfixgeneraldesc2') +
                ' ' +
                this.$t('FAQ.rentfixgeneraldesc3'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.payQ')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.payA'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.durationQ')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.durationA'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.otherFeeQ')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.otherFeeA'),
            },
          },
        ],
      };
    },
  },
  data: () => ({
    bannerText: 'FAQ.helpCenter',
  }),
  mounted() {
    if (this.$route.params.type) {
      const help = this.helpList.find(help => {
        return help.url === this.$route.params.type;
      });
      this.$store.commit('v2/help/SET_SELECTED_HELP', help);
    } else {
      this.$store.commit('v2/help/SET_SELECTED_HELP', this.helpList[0]);
    }
  },
};
</script>
